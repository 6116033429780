import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Divider, Col, Row, Typography, Menu, Button, Space } from "antd"
import Logo from "../icons/logo"
import {
  footerIcon,
  footerLogo,
  footerContainer,
  footerHeading,
  footerContent,
  footer,
} from "./footer.module.less"
import { RichText } from "prismic-reactjs"
import { FacebookIcon } from "../icons/facebook"
import { TwitterIcon } from "../icons/twitter"
import { InstagramIcon } from "../icons/instagram"
import { YoutubeIcon } from "../icons/youtube"
import { EmailIcon } from "../icons/email"

const FooterCol: React.FC = ({ children }) => {
  return <Col flex={1}>{children}</Col>
}

export const Footer = ({ parentLayout }) => {
  const { Footer: AntFooter } = parentLayout
  const titleLevel = 4
  const { Title } = Typography
  const query = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          bottom_navigation {
            link {
              uid
              url
              document {
                ... on PrismicPage {
                  data {
                    document_display_name {
                      richText
                    }
                  }
                }
              }
            }
            link_label {
              richText
            }
          }
        }
      }
    }
  `)
  const nav: { url: string; label: string; uid?: string }[] = (
    query?.prismicNavigation?.data?.bottom_navigation || []
  ).map((item) => {
    const { link, link_label } = item
    const label =
      RichText.asText(link_label.richText) ||
      RichText.asText(link.document.data.document_display_name.richText)
    return { url: link.url, label, uid: link.uid }
  })
  return (
    <div className={footerContainer}>
      <AntFooter className={footer}>
        <div className={footerContent}>
          {/* <div className={footerContent}> */}
          <Row gutter={16}>
            <FooterCol>
              <div className={footerLogo}>
                <Logo />
              </div>
            </FooterCol>
            <FooterCol>
              <Title className={footerHeading} level={titleLevel}>
                Main Menu
              </Title>
              <Menu mode="vertical" theme="dark">
                {nav &&
                  nav.map((link, i) => {
                    return (
                      <Menu.Item key={link.uid}>
                        <Link to={link.url}>{link.label}</Link>
                      </Menu.Item>
                    )
                  })}
              </Menu>
            </FooterCol>
            <FooterCol>
              <Title className={footerHeading} level={titleLevel}>
                Support
              </Title>
              <Menu mode="vertical" theme="dark">
                <Menu.Item key="distributors">
                  <Link to="/distributors/">Distributors</Link>
                </Menu.Item>
                <Menu.Item key="gallery">
                  <Link to="/growers-gallery/">Growers Gallery</Link>
                </Menu.Item>
                <Menu.Item key="testimonials">
                  <Link to="/testimonials/">Testimonials</Link>
                </Menu.Item>
                <Menu.Item key="locator">
                  <Link to="/store-locator/">Store Locator</Link>
                </Menu.Item>
                <Menu.Item key="press">
                  <Link to="/press/">Press</Link>
                </Menu.Item>
                <Menu.Item key="affiliates">
                  <Link to="/affiliates/">Affiliates</Link>
                </Menu.Item>
              </Menu>
            </FooterCol>
            <FooterCol>
              <Title className={footerHeading} level={titleLevel}>
                Follow Us
              </Title>
              <Menu mode="vertical" theme="dark">
                <Menu.Item key="facebook" icon={<FacebookIcon className={footerIcon}/>}>
                  <a href="https://www.facebook.com/dakinefourtwenty">Facebook</a>
                </Menu.Item>
                <Menu.Item key="twitter" icon={<TwitterIcon  className={footerIcon}/>}>
                  <a href="https://twitter.com/dakinefour20">Twitter</a>
                </Menu.Item>
                <Menu.Item key="instagram" icon={<InstagramIcon className={footerIcon} />}>
                  <a href="https://instagram.com/dakine__420">Instagram</a>
                </Menu.Item>
                <Menu.Item key="youtube" icon={<YoutubeIcon  className={footerIcon} />}>
                  <a href="https://www.youtube.com/channel/UCwtfD_nH37O2BtbvnfXT1GA">Youtube</a>
                </Menu.Item>
                <Menu.Item key="email" icon={<EmailIcon  className={footerIcon} />}>
                  <a href="mailto:info@dakine420.com">Email</a>
                </Menu.Item>
              </Menu>
            </FooterCol>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              Copyright &copy; {new Date().getFullYear()} · All rights reserved
            </Col>
            {/* <Col span={12}>Cards go here</Col> */}
          </Row>
          {/* <JsonDebugger json={{ nav, query }} /> */}
        </div>
      </AntFooter>
    </div>
  )
}
