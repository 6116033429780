import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "../icons/logo"
import { Navigation } from "./navigation"
// import { CartButton } from "./cart-button"
// import SearchIcon from "../icons/search"
import { Toast } from "./toast"
import {
  header,
  // container,
  headerContent,
  logo as logoCss,
  // searchButton,
  // nav,
} from "./header.module.less"
import { Col, Row, Space } from "antd"

export const Header = ({ parentLayout }) => {
  const { Header: AntHeader } = parentLayout
  const { checkout, loading, didJustAddToCart, customer, logout } =
    React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <div>
      <AntHeader className={header}>
        <div className={headerContent}>
          <Row wrap={false}>
            <Col flex="none">
              <Link to="/" className={logoCss}>
                <Logo theme="dark" width="120px" height="34px" />
              </Link>
            </Col>
            <Col flex="auto">
              <Navigation
                cartQuantity={quantity}
                authenticated={customer.authenticated}
                didJustAddToCart={didJustAddToCart}
                logout={logout}
              />
            </Col>
          </Row>
          {/* <Menu mode="horizontal" >
          <Menu.Item>
            <Link to="/search" className={searchButton}>
              <SearchIcon />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <CartButton quantity={quantity} />
          </Menu.Item>
        </Menu> */}
        </div>
      </AntHeader>
      {/* <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          "Updating…"
        ) : (
          <>
            Added to cart{" "}
            <svg
              width="14"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="#fff"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="#fff"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="#fff"
              />
            </svg>
          </>
        )}
      </Toast> */}
    </div>
  )
}
