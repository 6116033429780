/**
 * Make a cool shape for dividing content like shapedivider.app
 * https://css-tricks.com/svg-path-syntax-illustrated-guide/
 * https://haikei.app/ ... Its a video on the homepage, not on random
 */
import * as React from "react"

export const ShapeDivider: React.FC<{
  color?: string
  width?: number
  height?: number
  waves?: number
  offset?: number
  maxWaves?: number
  position?: "top" | "bottom"
  style?: unknown
  className?: string
  invert?: boolean
}> = ({
  color = "#FFF",
  width = 1000,
  height = 40,
  position = "top",
  waves = 1,
  maxWaves = 100,
  style,
  className,
  invert = false,
  offset = 0, // Negative X direction
}) => {
  const waveHeight = height / 2
  const waveWidth = width / 2 / waves
  const pathParts: string[] = []
  const negOffset = Math.abs(offset) * -1
  // Start the line. Use negative offset with mod width too prevent more than 1
  pathParts.push(`M ${negOffset % width},${height / 2}`)
  // For each wave create a curve pluss one more for the offset
  const waveCount = Math.min(waves + 1, maxWaves)
  const direction = invert ? -1 : 1 // -1 starts line moving up, 1 starts down
  for (let index = 0; index < waveCount; index++) {
    // Y Axis change for this wave
    const verticalShift = 0
    pathParts.push(
      // `q ${waveWidth / 2},${-waveHeight * 2} ${waveWidth},${verticalShift}`,
      `q ${waveWidth / 2},${
        direction * waveHeight * 2
      } ${waveWidth},${verticalShift}`,
      // `T ${waveWidth * (index + 1)}, ${height / 2}`
      `t ${waveWidth},0` // Relative move. Does not move pen
    )
  }
  if (position === "top") {
    pathParts.push(`L ${width},0 L 0,0`) // Move top to close
  } else {
    pathParts.push(`L ${width},${height} L 0,${height}`) // Move to close bottom
  }
  pathParts.push("Z") // End it properly. Like a text message with a period. ;)
  const path = pathParts.join(" ")

  return (
    <svg viewBox={`0 0 ${width} ${height}`} style={style} {...{ className }}>
      <path d={path} style={{ fill: color }} />
    </svg>
  )
}
