import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Badge, Col, Menu, Row } from "antd"
import Icon, { LoginOutlined } from "@ant-design/icons"
import { RichText } from "prismic-reactjs"
import { NavigationContext } from "../context/navigation-context"
// import { CartButton } from "./cart-button"
import SearchIconSvg from "../icons/search"
import CartIconSvg from "../icons/cart"
import { badge } from "./cart-button.module.css"
import { BarsIcon } from "../icons/bars"
import { menu, cart } from "./navigation.module.css"

// import { navStyle, navLink, activeLink } from "./navigation.module.css"

export function Navigation({
  className = menu,
  cartQuantity: quantity,
  logout = () => {},
  authenticated = false,
  didJustAddToCart = false,
}) {
  const queryData = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          top_navigation {
            link {
              uid
              id
              url
            }
            link_label {
              richText
            }
          }
        }
      }
      prismicNavigationV2 {
        data {
          body {
            ... on PrismicNavigationV2DataBody1stLevel {
              id
              slice_type
              primary {
                link_text
                nav_link {
                  url
                  uid
                  type
                  document {
                    ... on PrismicPage {
                      id
                      url
                    }
                    ... on PrismicProduct {
                      id
                      url
                    }
                    ... on PrismicProductType {
                      id
                      url
                    }
                  }
                }
              }
            }
            ... on PrismicNavigationV2DataBody2ndLevel {
              id
              slice_type
              primary {
                link_text
                nav_link {
                  url
                  uid
                  type
                  document {
                    ... on PrismicPage {
                      id
                      url
                    }
                    ... on PrismicProduct {
                      id
                      url
                    }
                    ... on PrismicProductType {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      products: allPrismicProductType(
        sort: { order: DESC, fields: data___sort_order }
      ) {
        types: nodes {
          data {
            name {
              richText
            }
          }
          url
        }
      }
    }
  `)
  const { navKey, setNavKey } = React.useContext(NavigationContext)
  // const [selectedMenu, setSelectedMenu] = React.useState(navKey);

  const handleMenuClick = ({ key }) => {
    setNavKey(key)
  }
  // console.log(queryData)
  const {
    prismicNavigation: navigation,
    products,
    prismicNavigationV2,
  } = queryData
  const { types = [] } = products
  const topNav = navigation.data.top_navigation
  // Reduce array of multiple level nav levels objects into an multi level array

  const topNav2 = (
    prismicNavigationV2.data.body as {
      id: string
      slice_type: string
      primary: {
        link_text: string
        nav_link: { url: string; uid: string }
      }
    }[]
  ).reduce((p, c, i, a) => {
    if (c.slice_type === "1st_level") {
      p.push({ ...c.primary, children: [] })
    } else {
      p[p.length - 1].children.push(c.primary)
    }
    return p
  }, [])
  // console.log({ topNav2 })
  // const HeartSvg = () => (
  //   <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
  //     <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
  //   </svg>
  // );
  // const SearchIconComp = props => <Icon component={<SearchIcon {...props} />} />
  const SearchIcon = (props) => <Icon component={SearchIconSvg} />

  const CartIcon = (props) => (
    <Icon
      component={() => (
        <Badge
          count={quantity}
          size="small"
          status="success"
          offset={[-16, -3]}
        >
          {/* <CartIconSvg /> */}
          {/* {CartIcon} */}
          <Icon component={CartIconSvg} style={{ color: "#FFFFFFA6" }} />
        </Badge>
      )}
    />
  )

  return (
    <>
      <Row gutter={16} wrap={false}>
        <Col flex="auto">
          <Menu
            className={[className].join(" ")}
            onClick={handleMenuClick}
            // selectedKeys={[navKey]}
            mode="horizontal"
            theme="dark"
            overflowedIndicator={
              <Badge
                count={quantity}
                size="small"
                status="success"
                offset={[-16, 25]}
                style={{ pointerEvents: "none" }}
              >
                <BarsIcon style={{ color: "#FFFFFFA6" }} />
              </Badge>
            }
          >
            {/* {topNav.map((navItem, index) => {
              if ("products" === navItem.link.uid) {
                return (
                  <Menu.SubMenu
                    key={navItem.link.uid}
                    title={RichText.asText(navItem.link_label.richText)}
                  >
                    {types.map((type, index) => {
                      return (
                        <Menu.Item key={index}>
                          <Link to={type.url}>
                            {RichText.asText(type.data.name.richText)}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>
                )
              }
              return (
                <Menu.Item key={navItem.link.uid}>
                  <Link to={navItem.link.url}>
                    {RichText.asText(navItem.link_label.richText)}
                  </Link>
                </Menu.Item>
              )
            })} */}
            {topNav2.map((navItem, index) => {
              // If the nav item has children, render a submenu
              if (navItem.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={`${index}-${navItem.nav_link.uid}`}
                    title={navItem.link_text}
                  >
                    {navItem.children.map((child, index) => {
                      return (
                        <Menu.Item key={child.nav_link.uid}>
                          <Link to={child.nav_link.document.url}>
                            {child.link_text}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>
                )
              } else {
                return (
                  <Menu.Item key={navItem.nav_link.uid}>
                    <Link to={navItem.nav_link.url}>{navItem.link_text}</Link>
                  </Menu.Item>
                )
              }
            })}

            <Menu.Item
              key="search"
              icon={<SearchIcon />}
              style={{ marginLeft: "auto" }}
            >
              <Link to="/search/">Search</Link>
            </Menu.Item>
            {authenticated ? (
              <Menu.SubMenu key="my-account" title="My Account">
                {/* <Menu.Item key="profile">
                  <Link to="/account/profile">Profile</Link>
                </Menu.Item> */}
                <Menu.Item key="orders">
                  <Link to="/account/orders">Orders</Link>
                </Menu.Item>
                <Menu.Item key="logout">
                  <Link
                    to="/account/logout"
                    onClick={(e) => {
                      e.preventDefault()
                      logout()
                    }}
                  >
                    Logout
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              // <Menu.Item key="account" icon={<CartIcon />}>
              //   <Link to="/account/">My account</Link>
              // </Menu.Item>
              <Menu.Item key="login" icon={<LoginOutlined />}>
                <Link to="/account/login">Login</Link>
              </Menu.Item>
            )}
            <Menu.Item key="cart" className={cart} icon={<CartIcon />}>
              <Link to="/cart" /* style={{ color: "#FFFFFFA6" }}*/>
                {/* <Badge
                  count={quantity}
                  size="small"
                  // status={didJustAddToCart ? "processing" : "success"}
                  status="success"
                  offset={[-53, 22]}
                  style={{ color: "#FFFFFFA6" }}
                > */}
                Cart
                {/* </Badge> */}
              </Link>
              {/* <CartButton quantity={quantity} /> */}
            </Menu.Item>
          </Menu>
        </Col>
        {/* {JSON.stringify({ selectedMenu })} */}
        {/* <Link to="/products/" onClick={() => setSelectedMenu('products')} >
          A
        </Link>
        <Link to="/learn/" onClick={() => setSelectedMenu('learn')} >
          B
        </Link> */}
      </Row>
    </>
  )
}
