import * as React from "react"
import { Seo } from "./seo"
import { Layout } from "antd"
import { SkipNavContent, SkipNavLink } from "./skip-nav"
import { Header } from "./header"
import { Footer } from "./footer"
import { ShapeDivider } from "./shape-divider"
// import {layoutContent} from "./page-content.module.less"

const AppLayout: React.FC<{
  id?: string
}> = ({ children, id }) => {
  // console.log(id)
  return (
    <div>
      <Seo />
      <Layout {...{ id }}>
        <SkipNavLink />
        <Header parentLayout={Layout} />
        <SkipNavContent>{children}</SkipNavContent>
        <ShapeDivider
          position="bottom"
          color="#101216"
          height={20}
          style={{ paddingTop: "10px" }}
        />
        <Footer parentLayout={Layout} />
      </Layout>
      {/* <HubSpotChatEmbed /> */}
    </div>
  )
}
export default AppLayout
