import * as React from "react"
// import { Layout as AntLayout } from "antd"

import { navLink } from "./skip-nav.module.less"


const defaultId = `skip-to-content`

export function SkipNavLink({
  children = `Skip to content`,
  contentId,
  ...props
}) {
  const id = contentId || defaultId

    // <a  {...props} href={`#${id}`} data-skip-to-content>
  return (
    <a className={navLink} {...props} href={`#${id}`} data-skip-to-content>
      {children}
    </a>
  )
}

/**
 * Wrap the main content of a page with this, thus also the <main> tag
 */
export function SkipNavContent({ children, id: idProp, ...props }) {
  const id = idProp || defaultId
  // const { Content } = AntLayout


  return (
    // <Content {...props} id={id}>{children}</Content>
    <main className='ant-layout-content' {...props} id={id}>{children}</main>
  )
}
